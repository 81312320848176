@import "vars";

/*==========  Desktop First  ==========*/

// ≥1200px
@include media-breakpoint-down(lg) {
  /* === Categories ===*/
  .category__img {
    max-width: 200px; } }

// ≥992px
@include media-breakpoint-down(md) {
  /* === Header === */
  .page-wrap {
    padding-top: 50px; }

  .container {
    max-width: 100%; }

  .header__inner {
    height: 50px; }

  .header__logo {
    flex-shrink: 0;
    padding: 0 15px; }

  .header__inner {
    margin-left: -15px;
    margin-right: -15px; }

  .search-mobail {
    display: flex; }

  .menu .menu__list {
    display: none; }

  .menu-mobile-links {
    display: block; }

  .header__menu {
    flex-grow: 1; }

  .menu .search-result {
    top: 0;
    padding-top: 50px;
    z-index: -1;
    position: fixed;
    height: 100%;
    max-height: 100%;
    &.search-result__visible {
      display: block;
      height: 100%;
      overflow-y: auto; } }

  .header__menu--active-search {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .form-search__btn-close {
      width: 50px; }

    > form {
      width: 100%; } }

  .header__menu .form-search__btn {
    display: none; }

  .header__menu > form {
    display: flex;
    flex-direction: row-reverse;
    top: 0;
    height: 100%;
    z-index: 1000;
    background: #fff;

    .form-search__group {
      position: relative; } }

  .header-user--mobile {
    display: flex;
    width: 46px;
    height: 46px; }

  .burger {
    display: block;
    &.visible {
      display: block; } }

  .menu-mobil {
    &.menu-mobil__open {
      transform: translate(0%, 0); } }

  /* === Section top 10 === */
  .best-set__list {
    .row {
      flex-wrap: nowrap; } }

  .best-set__item {
    width: 255px; }

  .popular__row {
    overflow-x: auto;
    flex-wrap: nowrap; }
  // Scroll snap properties
  // scroll-snap-type: proximity
  // scroll-snap-points-x: repeat(100%)

  /* === Section advantages === */
  .advantages__item {
    padding: 0;
    flex-direction: column;
    justify-content: center; }

  .advantages__icon {
    margin: 0 0 10px 0; }

  .advantages__text {
    text-align: center; }

  /* === Footer ===*/

  .logo-wrap {
    flex-shrink: 0; }

  .footer__menu {
    justify-content: flex-start;
    .menu__list {
      flex-direction: column; }
    .menu__item {
      &:not(:last-child) {
        margin-right: 0; } }
    .menu__link-lv1 {
      padding: 0; } }

  ul.footer__menu-main {
    margin: -6px 20px 0 0;
    flex-direction: column;
    > li {
      width: 100%;
      &:nth-child(2n+2) {
        width: 100%; } } }

  .footer__right {
    align-items: flex-end;
    flex-direction: row; }

  .footer__end > .container {
    grid-template: "menu menu menu" "logo logo socials"; }

  .footer__end-menu {
    justify-content: flex-start;
    margin-right: 0; }

  .footer__end-menu li a {
    font-size: 12px; }

  .footer__end-menu li::after {
    margin: 0 4px; }

  .footer-copyright {
    font-size: 13px; }

  .social-link img {
    width: 20px;
    height: 20px; }

  .social-links > li:not(:last-child) {
    margin-right: 7px; }

  .footer__inner .app-links {
    margin-right: 0;
    margin-top: 39px;
    margin-bottom: 37px;
    flex-direction: row; }

  /* === Section about === */
  .about__img {
    img {
      margin: 0 auto; } }

  /* === Blog === */
  .post-preview__img {
    height: 170px; }

  /* === Subscribe === */
  .subscribe__item {
    width: 389px;
    margin-bottom: 23px; }

  /* === Product read === */
  .product-read__header {
    padding-left: 30px;
    padding-right: 30px; }

  /* === Account === */
  .account__sitebar {
    margin-bottom: 50px; }

  /* === Bread crumbs ===*/
  .bread-crumbs__list {
    overflow-x: auto; } }

// ≥768px
@include media-breakpoint-down(sm) {
  .header__user {
    &.visible {
      display: block; } }

  .primary-header {
    text-align: left; }
  /* === Header === */
  .menu-mobil {
    max-width: 100%; }

  /* === Section our features === */
  .our-features__text {
    text-align: center;
    margin-bottom: 17px; }

  .our-features__item {
    margin-bottom: 30px; }

  .our-features__header {
    text-align: center; }

  .our-features__img {
    img {
      margin: 0 auto; } }

  .category-carousel .owl-nav .owl-next {
    right: -25px; }

  /* === Page category === */
  .page-category__img {
    margin-bottom: 30px; }

  .page-category__text {
    padding-top: 0; }

  /* === Similar produc === */
  .similar-product__list {
    .row {
      overflow-x: auto;
      flex-wrap: nowrap; } }

  /* === News === */

  .news__title {
    font-size: 20px;
    display: none; }

  .news__title-mobail {
    display: block; }

  .news__img {
    margin-bottom: 15px; }

  .news__content {
    font-size: 14px; }

  /* === Blog === */
  .blog__header {
    margin-bottom: 0; }

  .new-post-preview__img {
    height: 300px;
    width: 100%;
    max-width: none;
    margin-bottom: 15px;
    img {
      width: 100%; } }

  .post-preview {
    display: flex; }

  .post-preview__img {
    margin-right: 30px;
    max-width: 252px;
    height: 166px; }

  .blog__post-preview {
    margin-bottom: 15px; }

  /* === Read also === */
  .read-also__list {
    > .row {
      overflow-x: auto;
      flex-wrap: nowrap; } }

  /* === Modal === */
  .modal {
    height: auto;
    flex-direction: column; }

  .modal__main {
    align-self: center; }

  .auth-modal {
    width: 100%;
    .modal__content {
      flex-direction: column-reverse; }
    .modal__aside {
      border-bottom-right-radius: 0;
      border-top-left-radius: 40px; } }

  .security-container {
    flex-direction: column; }

  /* === Product read === */
  .product-read__text {
    padding: 0 30px; }

  /* === Account === */
  .settings-profile__row {
    flex-wrap: wrap; }

  .settings-profile__row--1 {
    .settings-profile__form-group {
      max-width: none; } }

  .settings-profile__form-group {
    margin-bottom: 10px; }

  /* === News === */
  .news__text {
    padding-top: 0;
    min-height: auto; }
  .cookie-new,
  .register-modal {
    background-image: none;
    width: 100%;
    .modal-right {
      width: 100%; } }
  .cookie-new .modal-body,
  .register-modal .modal-body {
    .modal-left {
      display: none; } }

  .footer__inner {
    grid-template-areas: 'footer_logo footer_logo footer_logo' 'footer_app footer_app footer_app' 'footer_menu footer_menu footer_menu'; } }


// ≥576px
@include media-breakpoint-down(xs) {
  /* === Header === */
  .header__logo {
    max-width: 180px;
    margin-right: 0; }

  .user__auth-logged-in {
    display: flex;
    margin: auto;
    .user__name {
      overflow: hidden;
      margin: auto; } }
  .user_is_logged .user_initials {
    width: 36px;
    height: 36px;
    background: #f3f3f3;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .user_is_logged .user__name {
    display: none; }

  .search-mobail {
    &.search-mobail--open {
      .search-mobail__btn {
        padding: 5px; } } }

  .search-mobail__btn-close {
    padding: 5px; }

  .newsletter-form__group {
    flex-wrap: wrap; }

  .newsletter-form__field {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 13px; }

  .newsletter-form__btn {
    width: 100%;
    border-radius: 6px; }

  /* === Footer ===*/
  .logo-wrap {
    width: 100%; }

  /* === Page category === */
  .sort__inner {
    overflow-x: auto; }

  .sort__caption {
    white-space: nowrap; }

  .product-card__img {
    margin-right: 15px; }

  .product-card__author {
    font-size: 14px; }

  .product-card__name {
    font-size: 16px; }

  .product-card__sticker {
    left: 146px; }

  /* === News === */
  .news__header {
    margin-bottom: 30px; }

  .news__img {
    width: 100%;
    img {
      width: 100%; } }

  /* === Blog === */
  .new-post-preview__img {
    height: 200px; }

  .post-preview__img {
    margin-right: 15px;
    max-width: 150px;
    height: 112px; }

  .post-preview__title {
    font-size: 16px; }

  .post-preview__subtitle {
    font-size: 14px; }

  .post-preview__info {
    height: 120px; }

  .post-preview__btn {
    min-width: auto; }

  /* === Article === */
  .article__banner {
    height: 200px; }

  .recommend-friends {
    span {
      font-size: 14px; } }

  /* === Subscribe === */
  .subscribe__item {
    width: 100%; }

  /* === Confidentiality === */
  .confidentiality__header {
    font-size: 28px; }

  /* === Modal === */
  .modal__main {
    padding-left: 25px;
    padding-right: 25px;
    align-self: center; }

  .control-profile__wrap-table {
    overflow-x: auto; }

  .control-profile__table {
    width: 460px; }

  /* === Product listen === */
  .product-listen__player {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px; }

  .audio-player__volum-slider {
    width: 80px; }

  /* === Contact === */
  .contact__link {
    > a {
      display: block; } } }


/*==========  Mobile First  ==========*/

// ≤576px
@include media-breakpoint-up(sm);

// ≤768px
@include media-breakpoint-up(md);

// ≤992px
@include media-breakpoint-up(lg) {
  /* === Subscribe === */
  .subscribe__item {
    &:hover {
      z-index: 2;
      transform: scale(1.1);
      box-shadow: 0 30px 30px rgba(0,0,0,.2); } }

  .menu__item-list {
    &:hover {
      .menu_level_1 {
        opacity: 1;
        pointer-events: auto;
        transform: translate(0, 0); } } } }


// ≤1200px
@include media-breakpoint-up(xl);
