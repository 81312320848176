@import 'assets/scss/vars';

.notify {
  opacity: 0;
  z-index: -1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  transition: all 300ms;

  &.warn {
    background: #ea6c6c;
    color: #fff; }

  &-body {
    padding: 10px 15px 10px 15px; }

  &.shown {
    opacity: 1;
    z-index: 100;
    transition: all 300ms; }

  &.ok {
    background: $accent;
    color: #fff; } }

.notify .close {
  color: white;
  font-size: 21px;
  line-height: 1;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none; }
